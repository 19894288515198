#asso {
	#presentation {
		.picture {
			background: transparent url('../images/photo11.jpg') center no-repeat;
			background-size: cover;
			@media screen and (min-width: $medium) {
				height: 900px;
				background-position: center;
			}
			@media screen and (min-width: $large) {
				height: 800px;
			}
		}
	}
	#actus {
		background-color: $grey;
		.blk {
			padding: 20px 20px;
			text-align: center;
			@media screen and (min-width: $large) {
				padding: 40px 80px;
			}
			h2 {
				text-align: center;
				&:after {
					content: '';
					display: block;
					margin:  20px auto 40px;
				}
			}
			h3 {
				margin-bottom: 20px;
				font-family: $raleway;
				font-weight: 700;
				color: $secondary;
				text-transform: uppercase;
				@media screen and (min-width: $large) {
					font-size: 20px;
				}
			}
			p {
				text-align: center;
				&:after {
					content: '';
					display: block;
					width: 100px;
					height: 2px;
					background-color: $secondary;
					margin: 20px auto 40px;
					text-align: center;
				}
			}
		}
	}
	#contact {
		.content {
			p {
				&:after {
					display: none;
				}
			}
			address {
				margin-bottom: 20px;
			}
			a {
				color: #000;
			}
			.phone {
				&:before {
					content: '';
					display: inline-block;
					width: 10px;
					height:10px;
					background:transparent url(../images/phone.svg) center no-repeat;
					margin-right: 10px;
				}
			}
		}
		.picture {
			background: transparent url('../images/photo7.jpg') bottom center no-repeat;
			background-size: cover;
			@media screen and (min-width: $medium) {
				height: 900px;
				background-position: center;
			}
			@media screen and (min-width: $large) {
				height: 600px;
			}
		}
	}
	#ca {
		.picture {
			background: transparent url('../images/photo8.jpg') bottom center no-repeat;
			background-size: cover;
			@media screen and (min-width: $medium) {
				height: 900px;
				background-position: center;
			}
			@media screen and (min-width: $large) {
				height: 600px;
			}
		}
		strong {
			font-weight: 700;
		}
	}
}