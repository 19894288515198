header {
	background-color: rgba(255,255,255,.9);
	width: 100%;
	padding: 15px 30px;
	z-index: 10;
	transition: .5s height linear;
	#hamburger {
		position: absolute;
		top: 4px;
		right: 0;
		padding: 10px;
		@media screen and (min-width: $large) {
			display: none;
		}
	}
	@media screen and (min-width: $medium) {
		background-color: #fff;
	}
	&.fix {
		background-color: rgba(255,255,255,.9);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		box-shadow: 0 0 20px rgba(0,0,0,.6);
		@media screen and (min-width: $large) {
			padding: 5px 30px;
			height: 50px;
		}
		.logo {
			@media screen and (min-width: $large) {
				width: 100px;
				height: 10px;
			}
		}
		nav {
			margin-top: 10px;
			@media screen and (min-width: $medium) {
				margin: 0;
			}
			a {
				@media screen and (min-width: $medium) {
					font-size: 16px;
				}
				&.active:after {
					margin-top: 2px;
					height: 3px;
				}
			}
		}
	}
	@media screen and (min-width: $large) {
		height: auto;
	}
	#current {
		display: none;
	}
	.logo {
		width: 120px;
		height: auto;
		margin: 0 auto;
		img {
			width: 100%;
			height: auto;
		}
		@media screen and (min-width: $medium) {
			float: none;
			margin: 0 auto;
			width: 200px;
		}
		@media screen and (min-width: $large) {
			float: left;
			margin: 0;
		}
	}
	nav {
		position: relative;
		margin-top: 20px;
		display: none;
		@media screen and (min-width: $large) {
			margin-top: 10px;
			float: right;
			display: block;
		}
		ul {
			margin: 0;
			padding: 0;
			text-align: center;
			li {
				margin: 10px 0;
				@media screen and (min-width: $large) {
					display: inline-block;
					margin-right: 20px;
				}
				a {
					font-size: 20px;
					font-weight: 600;
					color: $secondary;
					text-decoration: none;
					&.active:after {
						@media screen and (min-width: $large) {
							content: '';
							display: block;
							width: 100%;
							height: 5px;
							background-color: $primary;
							margin-top: 10px;
						}
					}
				}
			}
		}
	}
}