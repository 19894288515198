#infos {
	#intro {
		.picture {
			background: transparent url('../images/photo4.jpg') top center no-repeat;
			background-size: cover;
			@media screen and (min-width: $medium) {
				height: 900px;
				background-position: center;
			}
		}
	}
	#guide {
		.picture {
			background: transparent url('../images/photo5.jpg') center no-repeat;
			background-size: cover;
		}
	}
}