#teachers {
	margin: 20px auto;
	height: auto;
	position: relative;
	@media screen and (min-width: $medium) {
		padding: 0 50px;
	}
	h2 {
		text-align: center;
		@media screen and (min-width: $medium) {
			text-align: left;
		}
	}
	.menu {
		text-align: center;
		// display: inline-block;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		position: relative;

		// left: 50%;
		// transform: translateX(-50%);
		a {
			text-decoration: none;
		}
		a:last-child .teacher{
			margin: 0;
		}
		.teacher {
			margin-right: 10px;
			cursor: pointer;
			height: 130px;
			@media screen and (min-width: $medium) {
				margin-right: 20px;
				height: 230px;
			}
			&.active {
				.name {
					font-weight: 700;
				}
				&:after {
					content: '';
					display: 'block';
					width: 50px;
					height: 5px;
					margin: 20px auto 0 auto;
					background-color: $primary;
					@media screen and (min-width: $medium) {
						width: 100px;
					}
				}
			}
			.photo {
				background: transparent url('../images/prof1.jpg') center no-repeat;
				background-size: cover;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				margin: 0 auto 20px ;
				@media screen and (min-width: $medium) {
					width: 150px;
					height: 150px;
				}
			}
			.name {
				color: $secondary;
				font-size: 16px;
				@media screen and (min-width: $medium) {
					font-size: 22px;
				}
			}
		}
	}
}
#bio {
	h2 {
		&:after {
			margin: 20px 0 0;
		}
	}
	.picture {
		background: transparent url('../images/portrait1.jpg') center no-repeat;
		background-size: cover;
	}
}