#verbatim {
	background-color: $primary;
	position: relative;
	padding: 40px 20px;
	@media screen and (min-width: $medium) {
		padding: 40px 100px;
	}
	.content {
		margin: 20px 0;
		cite {
			display: inline-block;
			font-size: 20px;
			line-height: 28px;
			margin-bottom: 20px;
			color: $secondary;
			@media screen and (min-width: $medium) {
				font-size: 40px;
				line-height: 48px;
			}
		}
		p {
			font-size: 16px;
			text-align: right;
			@media screen and (min-width: $medium) {
				font-size: 32px;
			}
		}
	}
}