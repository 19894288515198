@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Raleway:300,400,600,700|Roboto:400,700');

button:focus {outline:0 !important;}
.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
* {
	box-sizing: border-box;
}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */
body {
	padding: 0;
	max-height: 100%;
	height: 100%;
	font-family: $raleway;
}
.container {
	padding: 0;
	max-width: $max-width;
	margin: 0 auto;
}

h1 {

}

h2 {
	font-family: $abril;
	font-size: 24px;
	line-height: 28px;
	text-align: left;
	color: $secondary;
	margin-bottom: 20px;
	@media screen and (min-width: $medium) {
		font-size: 30px;
		line-height: 34px;
	}
	@media screen and (min-width: $large) {
		font-size: 40px;
		line-height: 44px;
	}
	&:after {
		content: '';
		display: block;
		width: 100px;
		height: 10px;
		background-color: $primary;
		margin-top: 20px;
		@media screen and (min-width: $medium) {
			margin: 20px 0 0;
		}
	}
}

p {
	font-family:$raleway;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 20px;
	color: $secondary;
	text-align: justify;
}

cite {
	font-family: $abril;
	font-size: 22px;
	margin-bottom: 20px;
}

.cta {
	font-family: $raleway;
	background-color: $primary;
	color: $secondary;
	text-transform: uppercase;
	font-size: 16px;
	padding: 10px 30px;
	text-decoration: none;
	font-weight: 700;
	border-radius: 15px;
	text-align: center;
	display: inline-block;
	width: 100%;
	box-shadow: 0 5px 20px rgba(229,226,218,.5);
	@media screen and (min-width: $medium) {
		width: auto;
	}
} 

.lotus {
	background-position: center;
	background-color: $primary;
	background-repeat: no-repeat;
	background-size: 60%;
	width: 80px;
	height: 80px;
	position: absolute;
	left: 20px;
	top: 360px;
	&-1 {
		background-image: url(../images/lotus.svg);
	}
	&-2 {
		background-image: url(../images/lotus2.svg);
	}
	&-3 {
		background-image: url(../images/lotus3.svg);
	}
	&-4 {
		background-image: url(../images/lotus4.svg);
	}
	&-5 {
		background-image: url(../images/lotus5.svg);
	}
	@media screen and (min-width: $medium) {
		width: 100px;
		height: 100px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	@media screen and (min-width: $large) {
		width: 140px;
		height: 140px;
	}
}

.wrapp {
	padding: 0;
	max-width: $wrapp;
	margin: 0 auto;
}

address {
	line-height: 26px;
}

.blks {
	position: relative;
	&.inverse {
		@media screen and (min-width: $medium) {
			.blk-1 {
				float: right;
			}
			.blk-2 {
				float: left;
			}
		}
	}
	.blk {
		@media screen and (min-width: $medium) {
			float:left;
			width: 50%;
		}
		&.content {
			padding: 60px 20px 40px;
			@media screen and (min-width: $medium) {
				padding: 100px 100px 0;
			}
			p:last-of-type:after {
				content: '';
				display: block;
				width: 20px;
				height: 5px;
				background-color: $secondary;
				margin-top: 60px;
			}
		}
		&.picture {
			max-width: 100%;
			height: 400px;
			display: block;
			background-color: $secondary;
			@media screen and (min-width: $medium) {
				height: 600px;
			}
			@media screen and (min-width: $large) {
				height: 800px;
			}
		}
	}
}
