#maps {
	.picture {
		background: transparent url('../images/photo10.jpg') center no-repeat;
		background-size: cover;
	}
	.blk {
		&-2 {
			padding: 0;
			.text {
				padding: 40px;
			}
		}

	}
	iframe {
		width: 100%;
		height: 400px;
	}
}
#calendar_week {
	margin: 20px auto;
	height: auto;
	position: relative;
	@media screen and (min-width: $medium) {
		padding: 0 50px;
	}
	h2 {
		display: inline-block;
		position: relative;
		sup {
			position: absolute;
			top: -12px;
			right: -10px;
			font-size: 16px;
		}
	}
	.classrooms {
		margin-bottom: 20px;
		ul {
			text-align: center;
			margin: 0;
			li {
				cursor: pointer;
				margin-bottom: 20px;
				@media screen and (min-width: $medium) {
					margin: 0;
				}
				p {
					font-size: 20px;
					text-decoration: none;
					color: $secondary;
					font-weight: 700;
					text-align: center;
				}
				@media screen and (min-width: $medium) {
					display: block;
					float: left;
					height: 76px;
					padding: 10px 0;
					width: calc(100% / 5);
					background-color: transparent;
					border-bottom: 4px solid $secondary;
					transition: .3s all ease-in;
					&.active {
						background-color: $primary;
						p {
							color: $secondary;
							font-weight: 700;
						}
					}
					p {
						font-size: 16px;
						font-weight: 300;
						display: block;
						color: $secondary;
						padding: 10px 20px;
						text-decoration: none;	
					}
				}
				@media screen and (min-width: $large) {
				}
			}
		}
	}
	.infos {
		margin-bottom: 20px;
		margin-left: 30px;
		font-style: italic;
		font-size: 14px;
		@media screen and (min-width: $medium) {
			margin-left: 0;
			font-size: 16px;
		}
	}
	.calendar {
		margin: 0 auto 20px;
	}
	.days {
		.day {
			width: calc((100% / 7) - 0.1px);
			height: 20px;
			font-weight: 700;
			margin-left: 30px;
			margin-bottom: 20px;
			position: relative;
			&:before {
				content: '';
				display: block;
				width: 20px;
				height: 16px;
				background-color: $primary;
				position: absolute;
				left: -30px;
				@media screen and (min-width: $medium) {
					display: none;
				}
			}
			@media screen and (min-width: $medium) {
				margin: 0;
				float: left;
				text-align: center;
			}
		}
		.classe {
			margin-left: 30px;
		}
	}
	.content {
		display: none;
		background-color: $grey;
		@media screen and (min-width: $medium) {
			display: block; 
		}
		.case {
			border: 4px solid $grey;
			border-left: none;
			background-color: #fff;
			width: calc((100% / 7) - 0.1px);
			float: left;
			font-family: $raleway;
			position: relative;
			&:first-child {
				border-left: 4px solid $grey;
			}
			.am {
				min-height: 120px;
			}
			.pm {
				min-height: 120px;
			}
			&.inactive {
				visibility: hidden;
			}	
			&.inverse {
				background-color: $grey;
			}
			.classe {
				font-size: 14px;
				color: $grey;
				font-weight: 700;
				background-color: #715F32;
				padding: 10px;
				border-bottom: 2px solid #fff;
			}
		}
	}
}
#tarifs {
	ul {
		margin-bottom: 20px;
		li {
			margin-bottom: 20px;
			font-size: 14px;
			@media screen and (min-width: $medium) {
				font-size: 20px;
			}
			strong {
				font-weight: 700;
			}
		}
	}
	p {
		&:after {
			display: none;
		}
	}
	.help {
		font-style: italic;
		font-weight: 700;
		font-size: 13px;
		color: #000;
		margin-bottom: 40px;
	}
	.picture {
		background: transparent url('../images/photo9.jpg') left bottom no-repeat;
		@media screen and (min-width: $medium) {
			height: 600px;
		}
	}
}