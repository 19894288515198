#contact {
	#formulaire {
		margin-bottom: 20px;
		padding: 20px;
		h2 {
			text-align: center;
			&:after {
				content:'';
				display: none;
			}
		}
		form {
			max-width: 1000px;
			margin: 0 auto;
			.error {
				color:  #ff0000;
				font-style: italic;
				font-size: 14px;
				display: none;
			}
			.line {
				margin-bottom: 20px;
				label {
					display: block;
					margin-bottom: 5px;
				}
				input, textarea {
					width: 100%;
					display: block;
					font-size: 16px;
					margin-bottom: 5px;
				}
				textarea {
					height: 200px;
				}
			}
			.cta {
				display: block;
				width: 200px;
				margin: 0 auto;
			}
			.response {
				font-size: 16px;
				font-style: italic;
				&.ok {
					color: green;
				}
				&.ko {
					color: red;
				}
			}
		}
	}
}