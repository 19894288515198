.show-mobile {
	display: block;
	@media screen and (min-width: $medium) {
		display: none;
	}
}

.hide-mobile {
	display: none;
	@media screen and (min-width: $medium) {
		display: block;
	}
}