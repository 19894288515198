#home {
	#actus {
		.blk {
			padding: 20px 20px;
			text-align: center;
			@media screen and (min-width: $large) {
				padding: 40px 80px;
			}
			h2 {
				text-align: center;
				&:after {
					content: '';
					display: block;
					margin:  20px auto 40px;
				}
			}
			h3 {
				margin-bottom: 20px;
				font-family: $raleway;
				font-weight: 700;
				color: $secondary;
				@media screen and (min-width: $large) {
					font-size: 20px;
				}
			}
			p {
				text-align: center;
			}
		}
	}
	#yoga { 
		.blk-1 {
			display: none;
			@media screen and (min-width: $large) {
				display: block;
			}
		}
		.blk-2 {
			width: 100%;
			@media screen and (min-width: $large) {
				width: 50%;
			}
		}
		.picture {
			height: 800px;
			@media screen and (min-width: $medium) {
				background-position: left center;
				
			}
			@media screen and (min-width: $medium) {
			}
			&.accueil-1 {
				background: transparent url('../images/accueil1.jpg') center no-repeat;
				background-size: cover;
			}
			&.accueil-2 {
				background: transparent url('../images/accueil2.jpg') center no-repeat;
				background-size: cover;
			}
			&.accueil-3 {
				background: transparent url('../images/accueil3.jpg') center no-repeat;
				background-size: cover;
			}
			&.accueil-4 {
				background: transparent url('../images/accueil4.jpg') center no-repeat;
				background-size: cover;
			}
		}
		.content {
			@media screen and (min-width: $medium) {
				margin-bottom: 40px;
			}
		}
	}
	#teachers {
		background-color: $grey;
		margin: 0;
		h2 {
			text-align: center;
		}
		.blk {
			padding: 40px 20px;
			text-align: center;
			h2 {
				margin-bottom: 40px;
				&:after {
					display: none;
				}
			}
			.slider {
				overflow: hidden;
				height: auto;
				margin-bottom: 20px;
				.content {
					transition: .5s all ease;
				}
			}
			.dots {
				text-align: center;
				margin-bottom: 20px;
				@media screen and (min-width: $medium) {
					display: none;
				}
				.dot {
					width: 30px;
					height: 30px;
					background-color: $grey;
					border: 1px solid $secondary;
					border-radius: 50%;
					display: inline-block;
					cursor: pointer;
					margin: 0 10px;
					&.active {
						background-color: $primary;
					}
				}
			}
		}
		.teacher {
			float: left;
			margin: 0 10px 0 0;
			width: 100%;
			
			@media screen and (min-width: $medium) {
				// width: calc(((100% - 30px) / 4) - 0.1px);
				width: 24%;
				margin-bottom: 30px;
				&:nth-child(4n) {
					margin: 0 0 30px 0;
				}
				&:last-child {
					margin: 0 0 30px 0;
				}
			}
			.photo {
				width: 160px;
				height: 160px;
				border-radius: 50%;
				margin: 0 auto;
				background: $secondary url(../images/prof.jpg) center no-repeat;
				margin-bottom: 20px;
			}
			.name {
				text-transform: uppercase;
				font-size: 24px;
				padding: 20px 0;
				border-top: 1px solid $secondary;
				border-bottom: 1px solid $secondary;
				text-align: center;
				margin-bottom: 20px;
				color: $secondary;
				font-family: $abril;
			}
			.bio {
				text-align: left;
				&:after {
					content: '';
					display: none;
				}
			}
		}
	}
	#actus {
		background-color: $grey;
		.picture {
			background: transparent url('../images/photo2.jpg') center no-repeat;
			background-size: cover;
		}
		.content {
			h3 {
				margin-bottom: 20px;
				font-size: 20px;
				font-weight: 700;
			}
		}
	}

	#asso {
		.picture {
			background: transparent url('../images/photo1.jpg') center no-repeat;
			background-size: cover;
		}
	}

	#guide {
		.picture {
			background: transparent url('../images/photo3.jpg') center no-repeat;
			background-size: cover;
		}
	}
}