.noscroll { 
	overflow: hidden;
}

#overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.6);
	z-index: 9998;
	.close {
		position: fixed;
		top: 20px;
		right: 20px;
		width: 30px;
		height: 30px;
		background: transparent url(../images/close.png) center no-repeat;
	}
	.mentions {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 80px 20px 20px;
		padding: 40px;
		background-color: #fff;
		z-index: 9999;
		overflow: scroll;
		h3 {
			font-family: $abril;
			font-size: 22px;
			color: $secondary;
			margin: 20px 0;
		}
		h4 {
			font-family: $abril;
			font-size: 18px;
			color: $secondary;
			margin: 20px 0;
		}
		.vertu {
			font-family: $abril;
		}
		p {
			margin-bottom: 0;
		}
		address {
			font-style: italic;
			margin-bottom: 20px;
			color: $secondary;
		}

		a {
			margin-bottom: 20px;
			color: $secondary;
			font-weight: 700;
		}
	}
}

