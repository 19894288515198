.slider {
	height: 200px;
	@media screen and (min-width: $medium) {
		height: 500px;
	}
	position: relative;
	width: 100%;
	&-picture {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		&-1 {
			z-index: 2;
			background: #fff url(../images/slider1.jpg) center no-repeat;
			background-size: cover;
		}
		&-2 {
			z-index: 1;
			background: #fff url(../images/slider2.jpg) center no-repeat;
			background-size: cover;

		}
	}
}