#footer {
	background-color: $secondary;
	@media screen and (min-width: $large) {
		height: 400px;
	}
	.blk {
		padding: 40px 20px;
		@media screen and (min-width: $medium) {
			float: left;
		}
		address, p {
			color: $primary;
		}

		&-1 {
			background: #fff url(../images/logo_yogattitude.png) center center no-repeat;
			background-size: 50%;
			height: 400px;
			padding: 0;
			@media screen and (min-width: $large) {
				margin: 40px;
				width: 20%;
				height: 220px;
			}
		}
		&-2 {
			address {
				margin-bottom: 20px;
			}
			.phone {
				&:before {
					content: '';
					display: inline-block;
					width: 10px;
					height:10px;
					background:transparent url(../images/phone-inverse.svg) center no-repeat;
					margin-right: 10px;
				}
			}
			p {
				margin-bottom: 20px;
			}
			a {
				color: $primary;
			}
			@media screen and (min-width: $large) {
				width: 30%;
			}
		}
		&-3 {
			text-align: center;
			.cta {
				box-shadow: none;
			}
			@media screen and (min-width: $large) {
				width: 30%;
				text-align: left;
			}
		}
	}
}