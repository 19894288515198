@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Abril+Fatface|Raleway:300,400,600,700|Roboto:400,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button:focus {
  outline: 0 !important; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

* {
  box-sizing: border-box; }

* html .clearfix {
  zoom: 1; }

/* IE6 */
*:first-child + html .clearfix {
  zoom: 1; }

/* IE7 */
body {
  padding: 0;
  max-height: 100%;
  height: 100%;
  font-family: "Raleway", sans-serif; }

.container {
  padding: 0;
  max-width: 1920px;
  margin: 0 auto; }

h2 {
  font-family: "Abril Fatface", cursive;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: #715F32;
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 30px;
      line-height: 34px; } }
  @media screen and (min-width: 1024px) {
    h2 {
      font-size: 40px;
      line-height: 44px; } }
  h2:after {
    content: '';
    display: block;
    width: 100px;
    height: 10px;
    background-color: #FDF86B;
    margin-top: 20px; }
    @media screen and (min-width: 768px) {
      h2:after {
        margin: 20px 0 0; } }

p {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
  color: #715F32;
  text-align: justify; }

cite {
  font-family: "Abril Fatface", cursive;
  font-size: 22px;
  margin-bottom: 20px; }

.cta {
  font-family: "Raleway", sans-serif;
  background-color: #FDF86B;
  color: #715F32;
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px 30px;
  text-decoration: none;
  font-weight: 700;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  width: 100%;
  box-shadow: 0 5px 20px rgba(229, 226, 218, 0.5); }
  @media screen and (min-width: 768px) {
    .cta {
      width: auto; } }

.lotus {
  background-position: center;
  background-color: #FDF86B;
  background-repeat: no-repeat;
  background-size: 60%;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 20px;
  top: 360px; }
  .lotus-1 {
    background-image: url(../images/lotus.svg); }
  .lotus-2 {
    background-image: url(../images/lotus2.svg); }
  .lotus-3 {
    background-image: url(../images/lotus3.svg); }
  .lotus-4 {
    background-image: url(../images/lotus4.svg); }
  .lotus-5 {
    background-image: url(../images/lotus5.svg); }
  @media screen and (min-width: 768px) {
    .lotus {
      width: 100px;
      height: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }
  @media screen and (min-width: 1024px) {
    .lotus {
      width: 140px;
      height: 140px; } }

.wrapp {
  padding: 0;
  max-width: 1272px;
  margin: 0 auto; }

address {
  line-height: 26px; }

.blks {
  position: relative; }
  @media screen and (min-width: 768px) {
    .blks.inverse .blk-1 {
      float: right; }
    .blks.inverse .blk-2 {
      float: left; } }
  @media screen and (min-width: 768px) {
    .blks .blk {
      float: left;
      width: 50%; } }
  .blks .blk.content {
    padding: 60px 20px 40px; }
    @media screen and (min-width: 768px) {
      .blks .blk.content {
        padding: 100px 100px 0; } }
    .blks .blk.content p:last-of-type:after {
      content: '';
      display: block;
      width: 20px;
      height: 5px;
      background-color: #715F32;
      margin-top: 60px; }
  .blks .blk.picture {
    max-width: 100%;
    height: 400px;
    display: block;
    background-color: #715F32; }
    @media screen and (min-width: 768px) {
      .blks .blk.picture {
        height: 600px; } }
    @media screen and (min-width: 1024px) {
      .blks .blk.picture {
        height: 800px; } }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 20px;
  height: 17px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 3px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -7px; }
  .hamburger-inner::after {
    bottom: -7px; }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -14px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

header {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 15px 30px;
  z-index: 10;
  transition: .5s height linear; }
  header #hamburger {
    position: absolute;
    top: 4px;
    right: 0;
    padding: 10px; }
    @media screen and (min-width: 1024px) {
      header #hamburger {
        display: none; } }
  @media screen and (min-width: 768px) {
    header {
      background-color: #fff; } }
  header.fix {
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6); }
    @media screen and (min-width: 1024px) {
      header.fix {
        padding: 5px 30px;
        height: 50px; } }
    @media screen and (min-width: 1024px) {
      header.fix .logo {
        width: 100px;
        height: 10px; } }
    header.fix nav {
      margin-top: 10px; }
      @media screen and (min-width: 768px) {
        header.fix nav {
          margin: 0; } }
      @media screen and (min-width: 768px) {
        header.fix nav a {
          font-size: 16px; } }
      header.fix nav a.active:after {
        margin-top: 2px;
        height: 3px; }
  @media screen and (min-width: 1024px) {
    header {
      height: auto; } }
  header #current {
    display: none; }
  header .logo {
    width: 120px;
    height: auto;
    margin: 0 auto; }
    header .logo img {
      width: 100%;
      height: auto; }
    @media screen and (min-width: 768px) {
      header .logo {
        float: none;
        margin: 0 auto;
        width: 200px; } }
    @media screen and (min-width: 1024px) {
      header .logo {
        float: left;
        margin: 0; } }
  header nav {
    position: relative;
    margin-top: 20px;
    display: none; }
    @media screen and (min-width: 1024px) {
      header nav {
        margin-top: 10px;
        float: right;
        display: block; } }
    header nav ul {
      margin: 0;
      padding: 0;
      text-align: center; }
      header nav ul li {
        margin: 10px 0; }
        @media screen and (min-width: 1024px) {
          header nav ul li {
            display: inline-block;
            margin-right: 20px; } }
        header nav ul li a {
          font-size: 20px;
          font-weight: 600;
          color: #715F32;
          text-decoration: none; }
          @media screen and (min-width: 1024px) {
            header nav ul li a.active:after {
              content: '';
              display: block;
              width: 100%;
              height: 5px;
              background-color: #FDF86B;
              margin-top: 10px; } }

.slider {
  height: 200px;
  position: relative;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .slider {
      height: 500px; } }
  .slider-picture {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    .slider-picture-1 {
      z-index: 2;
      background: #fff url(../images/slider1.jpg) center no-repeat;
      background-size: cover; }
    .slider-picture-2 {
      z-index: 1;
      background: #fff url(../images/slider2.jpg) center no-repeat;
      background-size: cover; }

.show-mobile {
  display: block; }
  @media screen and (min-width: 768px) {
    .show-mobile {
      display: none; } }

.hide-mobile {
  display: none; }
  @media screen and (min-width: 768px) {
    .hide-mobile {
      display: block; } }

#maps .picture {
  background: transparent url("../images/photo10.jpg") center no-repeat;
  background-size: cover; }

#maps .blk-2 {
  padding: 0; }
  #maps .blk-2 .text {
    padding: 40px; }

#maps iframe {
  width: 100%;
  height: 400px; }

#calendar_week {
  margin: 20px auto;
  height: auto;
  position: relative; }
  @media screen and (min-width: 768px) {
    #calendar_week {
      padding: 0 50px; } }
  #calendar_week h2 {
    display: inline-block;
    position: relative; }
    #calendar_week h2 sup {
      position: absolute;
      top: -12px;
      right: -10px;
      font-size: 16px; }
  #calendar_week .classrooms {
    margin-bottom: 20px; }
    #calendar_week .classrooms ul {
      text-align: center;
      margin: 0; }
      #calendar_week .classrooms ul li {
        cursor: pointer;
        margin-bottom: 20px; }
        @media screen and (min-width: 768px) {
          #calendar_week .classrooms ul li {
            margin: 0; } }
        #calendar_week .classrooms ul li p {
          font-size: 20px;
          text-decoration: none;
          color: #715F32;
          font-weight: 700;
          text-align: center; }
        @media screen and (min-width: 768px) {
          #calendar_week .classrooms ul li {
            display: block;
            float: left;
            height: 76px;
            padding: 10px 0;
            width: calc(100% / 5);
            background-color: transparent;
            border-bottom: 4px solid #715F32;
            transition: .3s all ease-in; }
            #calendar_week .classrooms ul li.active {
              background-color: #FDF86B; }
              #calendar_week .classrooms ul li.active p {
                color: #715F32;
                font-weight: 700; }
            #calendar_week .classrooms ul li p {
              font-size: 16px;
              font-weight: 300;
              display: block;
              color: #715F32;
              padding: 10px 20px;
              text-decoration: none; } }
  #calendar_week .infos {
    margin-bottom: 20px;
    margin-left: 30px;
    font-style: italic;
    font-size: 14px; }
    @media screen and (min-width: 768px) {
      #calendar_week .infos {
        margin-left: 0;
        font-size: 16px; } }
  #calendar_week .calendar {
    margin: 0 auto 20px; }
  #calendar_week .days .day {
    width: calc((100% / 7) - 0.1px);
    height: 20px;
    font-weight: 700;
    margin-left: 30px;
    margin-bottom: 20px;
    position: relative; }
    #calendar_week .days .day:before {
      content: '';
      display: block;
      width: 20px;
      height: 16px;
      background-color: #FDF86B;
      position: absolute;
      left: -30px; }
      @media screen and (min-width: 768px) {
        #calendar_week .days .day:before {
          display: none; } }
    @media screen and (min-width: 768px) {
      #calendar_week .days .day {
        margin: 0;
        float: left;
        text-align: center; } }
  #calendar_week .days .classe {
    margin-left: 30px; }
  #calendar_week .content {
    display: none;
    background-color: #E5E2DA; }
    @media screen and (min-width: 768px) {
      #calendar_week .content {
        display: block; } }
    #calendar_week .content .case {
      border: 4px solid #E5E2DA;
      border-left: none;
      background-color: #fff;
      width: calc((100% / 7) - 0.1px);
      float: left;
      font-family: "Raleway", sans-serif;
      position: relative; }
      #calendar_week .content .case:first-child {
        border-left: 4px solid #E5E2DA; }
      #calendar_week .content .case .am {
        min-height: 120px; }
      #calendar_week .content .case .pm {
        min-height: 120px; }
      #calendar_week .content .case.inactive {
        visibility: hidden; }
      #calendar_week .content .case.inverse {
        background-color: #E5E2DA; }
      #calendar_week .content .case .classe {
        font-size: 14px;
        color: #E5E2DA;
        font-weight: 700;
        background-color: #715F32;
        padding: 10px;
        border-bottom: 2px solid #fff; }

#tarifs ul {
  margin-bottom: 20px; }
  #tarifs ul li {
    margin-bottom: 20px;
    font-size: 14px; }
    @media screen and (min-width: 768px) {
      #tarifs ul li {
        font-size: 20px; } }
    #tarifs ul li strong {
      font-weight: 700; }

#tarifs p:after {
  display: none; }

#tarifs .help {
  font-style: italic;
  font-weight: 700;
  font-size: 13px;
  color: #000;
  margin-bottom: 40px; }

#tarifs .picture {
  background: transparent url("../images/photo9.jpg") left bottom no-repeat; }
  @media screen and (min-width: 768px) {
    #tarifs .picture {
      height: 600px; } }

.noscroll {
  overflow: hidden; }

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998; }
  #overlay .close {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background: transparent url(../images/close.png) center no-repeat; }
  #overlay .mentions {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 80px 20px 20px;
    padding: 40px;
    background-color: #fff;
    z-index: 9999;
    overflow: scroll; }
    #overlay .mentions h3 {
      font-family: "Abril Fatface", cursive;
      font-size: 22px;
      color: #715F32;
      margin: 20px 0; }
    #overlay .mentions h4 {
      font-family: "Abril Fatface", cursive;
      font-size: 18px;
      color: #715F32;
      margin: 20px 0; }
    #overlay .mentions .vertu {
      font-family: "Abril Fatface", cursive; }
    #overlay .mentions p {
      margin-bottom: 0; }
    #overlay .mentions address {
      font-style: italic;
      margin-bottom: 20px;
      color: #715F32; }
    #overlay .mentions a {
      margin-bottom: 20px;
      color: #715F32;
      font-weight: 700; }

#footer {
  background-color: #715F32; }
  @media screen and (min-width: 1024px) {
    #footer {
      height: 400px; } }
  #footer .blk {
    padding: 40px 20px; }
    @media screen and (min-width: 768px) {
      #footer .blk {
        float: left; } }
    #footer .blk address, #footer .blk p {
      color: #FDF86B; }
    #footer .blk-1 {
      background: #fff url(../images/logo_yogattitude.png) center center no-repeat;
      background-size: 50%;
      height: 400px;
      padding: 0; }
      @media screen and (min-width: 1024px) {
        #footer .blk-1 {
          margin: 40px;
          width: 20%;
          height: 220px; } }
    #footer .blk-2 address {
      margin-bottom: 20px; }
    #footer .blk-2 .phone:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: transparent url(../images/phone-inverse.svg) center no-repeat;
      margin-right: 10px; }
    #footer .blk-2 p {
      margin-bottom: 20px; }
    #footer .blk-2 a {
      color: #FDF86B; }
    @media screen and (min-width: 1024px) {
      #footer .blk-2 {
        width: 30%; } }
    #footer .blk-3 {
      text-align: center; }
      #footer .blk-3 .cta {
        box-shadow: none; }
      @media screen and (min-width: 1024px) {
        #footer .blk-3 {
          width: 30%;
          text-align: left; } }

#verbatim {
  background-color: #FDF86B;
  position: relative;
  padding: 40px 20px; }
  @media screen and (min-width: 768px) {
    #verbatim {
      padding: 40px 100px; } }
  #verbatim .content {
    margin: 20px 0; }
    #verbatim .content cite {
      display: inline-block;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
      color: #715F32; }
      @media screen and (min-width: 768px) {
        #verbatim .content cite {
          font-size: 40px;
          line-height: 48px; } }
    #verbatim .content p {
      font-size: 16px;
      text-align: right; }
      @media screen and (min-width: 768px) {
        #verbatim .content p {
          font-size: 32px; } }

#home #actus .blk {
  padding: 20px 20px;
  text-align: center; }
  @media screen and (min-width: 1024px) {
    #home #actus .blk {
      padding: 40px 80px; } }
  #home #actus .blk h2 {
    text-align: center; }
    #home #actus .blk h2:after {
      content: '';
      display: block;
      margin: 20px auto 40px; }
  #home #actus .blk h3 {
    margin-bottom: 20px;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    color: #715F32; }
    @media screen and (min-width: 1024px) {
      #home #actus .blk h3 {
        font-size: 20px; } }
  #home #actus .blk p {
    text-align: center; }

#home #yoga .blk-1 {
  display: none; }
  @media screen and (min-width: 1024px) {
    #home #yoga .blk-1 {
      display: block; } }

#home #yoga .blk-2 {
  width: 100%; }
  @media screen and (min-width: 1024px) {
    #home #yoga .blk-2 {
      width: 50%; } }

#home #yoga .picture {
  height: 800px; }
  @media screen and (min-width: 768px) {
    #home #yoga .picture {
      background-position: left center; } }
  #home #yoga .picture.accueil-1 {
    background: transparent url("../images/accueil1.jpg") center no-repeat;
    background-size: cover; }
  #home #yoga .picture.accueil-2 {
    background: transparent url("../images/accueil2.jpg") center no-repeat;
    background-size: cover; }
  #home #yoga .picture.accueil-3 {
    background: transparent url("../images/accueil3.jpg") center no-repeat;
    background-size: cover; }
  #home #yoga .picture.accueil-4 {
    background: transparent url("../images/accueil4.jpg") center no-repeat;
    background-size: cover; }

@media screen and (min-width: 768px) {
  #home #yoga .content {
    margin-bottom: 40px; } }

#home #teachers {
  background-color: #E5E2DA;
  margin: 0; }
  #home #teachers h2 {
    text-align: center; }
  #home #teachers .blk {
    padding: 40px 20px;
    text-align: center; }
    #home #teachers .blk h2 {
      margin-bottom: 40px; }
      #home #teachers .blk h2:after {
        display: none; }
    #home #teachers .blk .slider {
      overflow: hidden;
      height: auto;
      margin-bottom: 20px; }
      #home #teachers .blk .slider .content {
        transition: .5s all ease; }
    #home #teachers .blk .dots {
      text-align: center;
      margin-bottom: 20px; }
      @media screen and (min-width: 768px) {
        #home #teachers .blk .dots {
          display: none; } }
      #home #teachers .blk .dots .dot {
        width: 30px;
        height: 30px;
        background-color: #E5E2DA;
        border: 1px solid #715F32;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
        margin: 0 10px; }
        #home #teachers .blk .dots .dot.active {
          background-color: #FDF86B; }
  #home #teachers .teacher {
    float: left;
    margin: 0 10px 0 0;
    width: 100%; }
    @media screen and (min-width: 768px) {
      #home #teachers .teacher {
        width: 24%;
        margin-bottom: 30px; }
        #home #teachers .teacher:nth-child(4n) {
          margin: 0 0 30px 0; }
        #home #teachers .teacher:last-child {
          margin: 0 0 30px 0; } }
    #home #teachers .teacher .photo {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      margin: 0 auto;
      background: #715F32 url(../images/prof.jpg) center no-repeat;
      margin-bottom: 20px; }
    #home #teachers .teacher .name {
      text-transform: uppercase;
      font-size: 24px;
      padding: 20px 0;
      border-top: 1px solid #715F32;
      border-bottom: 1px solid #715F32;
      text-align: center;
      margin-bottom: 20px;
      color: #715F32;
      font-family: "Abril Fatface", cursive; }
    #home #teachers .teacher .bio {
      text-align: left; }
      #home #teachers .teacher .bio:after {
        content: '';
        display: none; }

#home #actus {
  background-color: #E5E2DA; }
  #home #actus .picture {
    background: transparent url("../images/photo2.jpg") center no-repeat;
    background-size: cover; }
  #home #actus .content h3 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700; }

#home #asso .picture {
  background: transparent url("../images/photo1.jpg") center no-repeat;
  background-size: cover; }

#home #guide .picture {
  background: transparent url("../images/photo3.jpg") center no-repeat;
  background-size: cover; }

#teachers {
  margin: 20px auto;
  height: auto;
  position: relative; }
  @media screen and (min-width: 768px) {
    #teachers {
      padding: 0 50px; } }
  #teachers h2 {
    text-align: center; }
    @media screen and (min-width: 768px) {
      #teachers h2 {
        text-align: left; } }
  #teachers .menu {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative; }
    #teachers .menu a {
      text-decoration: none; }
    #teachers .menu a:last-child .teacher {
      margin: 0; }
    #teachers .menu .teacher {
      margin-right: 10px;
      cursor: pointer;
      height: 130px; }
      @media screen and (min-width: 768px) {
        #teachers .menu .teacher {
          margin-right: 20px;
          height: 230px; } }
      #teachers .menu .teacher.active .name {
        font-weight: 700; }
      #teachers .menu .teacher.active:after {
        content: '';
        display: 'block';
        width: 50px;
        height: 5px;
        margin: 20px auto 0 auto;
        background-color: #FDF86B; }
        @media screen and (min-width: 768px) {
          #teachers .menu .teacher.active:after {
            width: 100px; } }
      #teachers .menu .teacher .photo {
        background: transparent url("../images/prof1.jpg") center no-repeat;
        background-size: cover;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 0 auto 20px; }
        @media screen and (min-width: 768px) {
          #teachers .menu .teacher .photo {
            width: 150px;
            height: 150px; } }
      #teachers .menu .teacher .name {
        color: #715F32;
        font-size: 16px; }
        @media screen and (min-width: 768px) {
          #teachers .menu .teacher .name {
            font-size: 22px; } }

#bio h2:after {
  margin: 20px 0 0; }

#bio .picture {
  background: transparent url("../images/portrait1.jpg") center no-repeat;
  background-size: cover; }

#infos #intro .picture {
  background: transparent url("../images/photo4.jpg") top center no-repeat;
  background-size: cover; }
  @media screen and (min-width: 768px) {
    #infos #intro .picture {
      height: 900px;
      background-position: center; } }

#infos #guide .picture {
  background: transparent url("../images/photo5.jpg") center no-repeat;
  background-size: cover; }

#asso #presentation .picture {
  background: transparent url("../images/photo11.jpg") center no-repeat;
  background-size: cover; }
  @media screen and (min-width: 768px) {
    #asso #presentation .picture {
      height: 900px;
      background-position: center; } }
  @media screen and (min-width: 1024px) {
    #asso #presentation .picture {
      height: 800px; } }

#asso #actus {
  background-color: #E5E2DA; }
  #asso #actus .blk {
    padding: 20px 20px;
    text-align: center; }
    @media screen and (min-width: 1024px) {
      #asso #actus .blk {
        padding: 40px 80px; } }
    #asso #actus .blk h2 {
      text-align: center; }
      #asso #actus .blk h2:after {
        content: '';
        display: block;
        margin: 20px auto 40px; }
    #asso #actus .blk h3 {
      margin-bottom: 20px;
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      color: #715F32;
      text-transform: uppercase; }
      @media screen and (min-width: 1024px) {
        #asso #actus .blk h3 {
          font-size: 20px; } }
    #asso #actus .blk p {
      text-align: center; }
      #asso #actus .blk p:after {
        content: '';
        display: block;
        width: 100px;
        height: 2px;
        background-color: #715F32;
        margin: 20px auto 40px;
        text-align: center; }

#asso #contact .content  p:after {
  display: none; }

#asso #contact .content  address {
  margin-bottom: 20px; }

#asso #contact .content  a {
  color: #000; }

#asso #contact .content  .phone:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background: transparent url(../images/phone.svg) center no-repeat;
  margin-right: 10px; }

#asso #contact .picture {
  background: transparent url("../images/photo7.jpg") bottom center no-repeat;
  background-size: cover; }
  @media screen and (min-width: 768px) {
    #asso #contact .picture {
      height: 900px;
      background-position: center; } }
  @media screen and (min-width: 1024px) {
    #asso #contact .picture {
      height: 600px; } }

#asso #ca .picture {
  background: transparent url("../images/photo8.jpg") bottom center no-repeat;
  background-size: cover; }
  @media screen and (min-width: 768px) {
    #asso #ca .picture {
      height: 900px;
      background-position: center; } }
  @media screen and (min-width: 1024px) {
    #asso #ca .picture {
      height: 600px; } }

#asso #ca strong {
  font-weight: 700; }

#contact #formulaire {
  margin-bottom: 20px;
  padding: 20px; }
  #contact #formulaire h2 {
    text-align: center; }
    #contact #formulaire h2:after {
      content: '';
      display: none; }
  #contact #formulaire form {
    max-width: 1000px;
    margin: 0 auto; }
    #contact #formulaire form .error {
      color: #ff0000;
      font-style: italic;
      font-size: 14px;
      display: none; }
    #contact #formulaire form .line {
      margin-bottom: 20px; }
      #contact #formulaire form .line label {
        display: block;
        margin-bottom: 5px; }
      #contact #formulaire form .line input, #contact #formulaire form .line textarea {
        width: 100%;
        display: block;
        font-size: 16px;
        margin-bottom: 5px; }
      #contact #formulaire form .line textarea {
        height: 200px; }
    #contact #formulaire form .cta {
      display: block;
      width: 200px;
      margin: 0 auto; }
    #contact #formulaire form .response {
      font-size: 16px;
      font-style: italic; }
      #contact #formulaire form .response.ok {
        color: green; }
      #contact #formulaire form .response.ko {
        color: red; }
